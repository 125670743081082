import { inject, Injectable } from '@angular/core';
import { environment as Config } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { IoTGatewayGridDTO } from '../../iot/iot.model';
import { HttpClient } from '@angular/common/http';
import { SettingsDocumentDTO, SettingsTaskDTO, SettingsTicketDTO } from '../settings.model';

@Injectable({
  providedIn: 'root'
})

export class SettingsService {

  private readonly http = inject(HttpClient);
  private readonly baseUrlPath: string = Config.apiBaseUrl + '/settings';

  public getAllGatewayGridDTO (): Observable<IoTGatewayGridDTO[]> {
    return this.http.get<IoTGatewayGridDTO[]>(`${this.baseUrlPath}/iot/gateway`);
  }

  public updateGatewayIoTGridDTO (gateway: Partial<IoTGatewayGridDTO>): Observable<IoTGatewayGridDTO> {
    return this.http.patch<IoTGatewayGridDTO>(`${this.baseUrlPath}/iot/gateway/${gateway.id}`, gateway);
  }

  public getSettingsDocumentDTO (): Observable<SettingsDocumentDTO> {
    return this.http.get<SettingsDocumentDTO>(`${this.baseUrlPath}/document`);
  }

  public getSettingsTaskDTO (): Observable<SettingsTaskDTO> {
    return this.http.get<SettingsTaskDTO>(`${this.baseUrlPath}/task`);
  }

  public getSettingsTicketDTO (): Observable<SettingsTicketDTO> {
    return this.http.get<SettingsTicketDTO>(`${this.baseUrlPath}/ticket`);
  }

  public updateSettingsDocumentDTO (settings: SettingsDocumentDTO): Observable<SettingsDocumentDTO> {
    return this.http.patch<SettingsDocumentDTO>(`${this.baseUrlPath}/document`, settings);
  }

  public updateSettingsTaskDTO (settings: SettingsTaskDTO): Observable<SettingsTaskDTO> {
    return this.http.patch<SettingsTaskDTO>(`${this.baseUrlPath}/task`, settings);
  }

  public updateSettingsTicketDTO (settings: SettingsTicketDTO): Observable<SettingsTicketDTO> {
    return this.http.patch<SettingsTicketDTO>(`${this.baseUrlPath}/ticket`, settings);
  }

}
